<template>
    <v-container fluid>
        <v-form class="custom-form" ref="consumptionForm" @submit.prevent="saveConsumption">
            <v-row dense>
                <v-col cols="12">
                    <v-card :elevation="1" color="grey lighten-3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar
                                    color="#546E7A"
                                    :elevation="1"
                                    height="30px"
                                    dark
                                >
                                    <v-toolbar-title class="subtitle-2">Consumption Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-2">
                                    <v-row no-gutters class="mb-1">
                                        <v-col cols="4">
                                            <v-row no-gutters>
                                                <v-col cols="3">Assign To</v-col>
                                                <v-col cols="8">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="employee"
                                                        @focus="$store.dispatch('employee/getEmployees')"
                                                        :items="$store.getters['employee/employees']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn to="/employee" target="_blank" class="ml-1" height="25px" dark small block color="primary">
                                                        <v-icon class="pt-1">mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="4" class="text-right pr-1">Department</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        class="pr-1"
                                                        readonly
                                                        v-model="department"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="4" class="text-right pr-1">Designation</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        class="pr-1"
                                                        readonly
                                                        v-model="designation"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row no-gutters>
                                                <v-col cols="4" class="text-right pr-2">Mobile</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        class="pr-1"
                                                        readonly
                                                        v-model="mobile"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        
                                        
                                    </v-row>

                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-row dense>
                    </v-row>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-card :elevation="1" color="grey lighten-2">
                        <v-card-text class="py-2">
                            <v-row no-gutters>
                                <v-col cols="4">
                                    <v-row no-gutters>
                                        <v-col cols="3">Instrument</v-col>
                                        <v-col cols="8">
                                            <v-combobox
                                                    dense
                                                    outlined
                                                    hide-details
                                                    v-model="instrument"
                                                    @focus="$store.dispatch('instrument/getInstruments')"
                                                    :items="$store.getters['instrument/instruments']"
                                                    :loading="$store.getters['instrument/loading']"
                                                    item-text="display_text"
                                                    item-value="id"
                                                >
                                            </v-combobox>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn to="/store/instrument" target="_blank" class="ml-1" height="25px" dark small block color="primary">
                                                <v-icon class="pt-1">mdi-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="2">
                                    <v-row no-gutters>
                                        <v-col cols="5" class="text-right pr-1">Quantity</v-col>
                                        <v-col cols="7">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                v-model="consumption.quantity"
                                                @input="calculateTotal"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="3">
                                    <v-row no-gutters>
                                        <v-col cols="5" class="text-right pr-1">Available Stock</v-col>
                                        <v-col cols="7">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                :value="$store.getters['instrument/stock']"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1">
                                    <v-row no-gutters>
                                        <v-col cols="2"></v-col>
                                        <v-col cols="10">
                                            <v-btn height="26px" dark block color="light-blue darken-2" @click="addToCart">Add</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            
            <v-row dense>
                <v-col cols="9">
                    <v-simple-table  class="custom-data-table">
                        <template>
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Instrument Name</th>
                                    <th>Category</th>
                                    <th>Quantity</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cart, ind) in addtocart" :key="ind">
                                    <td>{{ ind + 1 }}</td>
                                    <td>{{ cart.name }}</td>
                                    <td>{{ cart.category }}</td>
                                    <td class="text-right">{{ cart.quantity }}</td>
                                    <td class="text-center">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    small
                                                    @click="removeCart(ind)"
                                                    color="error"
                                                    v-on="on"
                                                    >mdi-delete-circle-outline</v-icon
                                                >
                                            </template>
                                            <span>Delete</span>
                                        </v-tooltip>
                                    </td>
                                </tr> 
                                <tr style="display:none" v-bind:style="{display: addtocart.length > 0 ? '' : 'none'}">
                                    <td colspan="3" class="text-center"><strong>Note</strong></td>
                                    <td colspan="2" class="text-center"><strong>Total Quantity</strong></td>
                                </tr>
                                <tr style="display:none" v-bind:style="{display: addtocart.length > 0 ? '' : 'none'}">
                                    <td colspan="3" style="padding:5px 0px">
                                        <v-textarea
                                            dense
                                            outlined
                                            hide-details
                                            height="7vh"
                                            v-model="consumption.note"
                                        ></v-textarea>
                                    </td>
                                    <td colspan="2" class="text-center">
                                        <strong> {{ total_quantity }}</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="3" class="pb-0">
                    <v-card :elevation="1" color="grey lighten-3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col md="12" cols="12">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Invoice</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :value="$store.getters['consumption/invoice']"
                                                        readonly
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3">Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="consumption.assign_date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="consumption.assign_date"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row dense>
                                                            <v-col cols="6">
                                                            <v-btn height="28px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn height="28px" type="submit" dark block color="light-blue darken-2" :loading="saveLoading">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>

<script>
import swal from 'sweetalert';
export default {
    name: "ConsumptionEntry",

    data: () => ({
        consumption: {
            id: null,
            employee_id: null,
            assign_date: new Date().toISOString().substr(0, 10),
            instrument_id: null,
            quantity: 0,
        },
        mobile: '',
        department: '',
        designation: '',
        total_stock: 0,
        stock: 0,

        instrument: null,
        employee: null,
        addtocart: [],
        total_quantity: 0,

        saveLoading: false,

        editMode: false,
        editCartItemIndex: null,

        deletedCartItemsId: []
    }),

    watch: {
        instrument(instrument) {
            if (instrument == null) return
            this.instrument = instrument;
            this.consumption.instrument_id = instrument.id;
            this.$store.dispatch('instrument/getInstrumentStock', {instrument_id: instrument.id })
        },

        employee(employee) {
            if(employee == null) return
            this.consumption.employee_id = employee.id;
            this.mobile = employee.contact_number;
            this.department = employee.department.name;
            this.designation = employee.designation.name;
        }
    },

    created() {
        this.getInvoice();
        if (this.$route.params.id != undefined)
            this.editConsumption(this.$route.params.id);
    },

    methods: {
        getInvoice() {
            this.$store.dispatch('consumption/getInvoices');
        },

        async editConsumption(consumptionId) {
            let consumption = await this.$store.dispatch('consumption/getConsumption', {id: consumptionId})
            if (consumption == null) this.$router.push(`/consumption-record`)
            this.consumption.id = consumption.id
            this.consumption.employee_id = consumption.employee_id
            this.consumption.assign_date = consumption.assign_date
            this.employee = consumption.employee
            consumption.consumption_details.forEach(item => {
                this.addtocart.unshift({
                    id: item.id,
                    instrument_id: item.instrument.id,
                    name: item.instrument.name,
                    category: item.instrument.instrument_category.name,
                    quantity: item.quantity
                })
            });

            this.editMode = true;
            this.$store.dispatch("instrument/getInstruments");
        },

        addToCart() {
            if(this.consumption.instrument_id == null) {
                swal({
                        title: 'Select instrument !',
                        icon: 'warning'
                    })
                return
            }

            if(this.consumption.quantity == 0) {
                swal({
                        title: 'Quantity is required !',
                        icon: 'warning'
                    })
                return
            }

            if(this.$store.getters['instrument/stock'] <= 0 ) {
                swal({
                    title: 'Instrument stock is not available! Please purchase first',
                    icon: 'warning'
                })
                return
            }

            let cartInd = this.addtocart.findIndex(i => i.instrument_id == this.consumption.instrument_id);

            if(this.editCartItemIndex == null) {
                if(cartInd > -1){
                    swal({
                        title: 'Instrument existed in cart !',
                        icon: 'warning'
                    })
                    return
                }
                    
                if(this.$store.getters['instrument/stock'] < this.consumption.quantity) {
                    swal({
                        title: 'Stock quantity is too large !',
                        icon: 'warning'
                    })
                    return
                }
    
                let instrument = {
                    instrument_id: this.instrument.id,
                    name: this.instrument.name,
                    category: this.instrument.instrument_category.name,
                    quantity: this.consumption.quantity
                }

                this.addtocart.push({...instrument});
            }
            else {
                if (cartInd > -1 && cartInd != this.editCartItemIndex) {
                    let cartItem = this.addtocart[this.editCartItemIndex];
                    this.instrument = this.getInstrumentById(cartItem.instrument_id);
                    this.$store.dispatch("snackbar/error", "Instrument existed in cart");
                    return;
                }

                if(this.$store.getters['instrument/stock'] < this.consumption.quantity) {
                    swal({
                        title: 'Stock quantity is too large !',
                        icon: 'warning'
                    })
                    return
                }

                let currentEditItem = this.addtocart[this.editCartItemIndex];
                currentEditItem.instrument_id = this.instrument.id;
                currentEditItem.name = this.instrument.name;
                currentEditItem.category = this.instrument.instrument_category.name;
                currentEditItem.quantity = this.consumption.quantity;
            }

            this.calculateTotal();
            this.resetCart();
        },

        editCartItem(ind) {
            let item = this.addtocart[ind];
            this.instrument = this.getInstrumentById(item.instrument_id);
            this.consumption.quantity = item.quantity;
            this.editCartItemIndex = ind;
        },

        getInstrumentById(instrument_id) {
            let instruments = this.$store.getters["instrument/instruments"];
            let instrument = instruments.filter(ins => ins.id == instrument_id);
            return instrument.length > 0 ? instrument[0] : null;
        },

        calculateTotal() {
            this.total_quantity = this.addtocart.reduce((prev, curr) => { return prev + parseFloat(curr.quantity); }, 0);
        },

        resetCart() {
            this.consumption.quantity = '';
            this.instrument = '';
            this.editCartItemIndex = null;
        },

        removeCart(ind) {
            let item = this.addtocart[ind];
            if (item.id != undefined) this.deletedCartItemsId.push(item.id);
            this.addtocart.splice(ind, 1);
            this.calculateTotal();
        },

        async saveConsumption() {

            if(this.addtocart.length  == 0) {
				swal({
                    title: 'Cart is empty ! Please add to cart.',
                    icon: 'warning',
                })
                return
            }

            if(this.consumption.employee_id == null) {
                swal({
                    title: 'Select assign to employee !',
                    icon: 'warning',
                })
                return
            }

            this.saveLoading = true;
            delete this.consumption['quantity'];
            delete this.consumption['instrument_id'];

            if (this.$route.params.id != undefined && this.editMode == true) {
                let res = await this.$store.dispatch('consumption/saveConsumptions', { 
                    ...this.consumption, 
                    cart: this.addtocart,
                    deleteItemsId: this.deletedCartItemsId,
                    id: this.$route.params.id
                })

                if (res.isSuccess) this.$router.go(-1);
            } 
            else {
                let res = await this.$store.dispatch('consumption/saveConsumptions', { ...this.consumption, cart: this.addtocart })
                if(res.isSuccess) {
                    this.resetForm();

                    swal({
                        title: 'Do you want to show invoice?',
                        icon: 'success',
                        buttons: true
                    }).then(response => {
                        if (response) {
                            if (response) this.$router.push(`/consumption-invoice/${res.id}`)
                        }
                    })
                }
                this.saveLoading = false;
            }
        },

        resetForm() {
            this.$refs.consumptionForm.reset();
            this.addtocart = [];
        }
    }
};
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        font-size: 12px;
        padding: 0px 1px;
        background: #607D8B;
        color: #ffffff;
        border: 1px solid #ccc;
        text-align: center;
        height: 24px !important;
        letter-spacing: .7px;
    }
    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 20px !important;
        padding: 0px 5px !important;
        font-size: 12px !important;
        border: 1px solid #ccc !important;
        text-align: center;
    }
</style>